/* eslint-disable */
import axios from 'axios'
import { API_CONFIG } from '../config/api.config.js'

export const state = () => ({
  campMemberChurches: [],
  campProfile: {},
  currentCamp: {},
  homeCamp: {},
  homeState: {},
  outsideStateCampList: [],
  formattedProfile: {},
  state_or_camp: '',
  stateCampList: [],
  stateLevel: {},
  stateUndist: {},
  wsmData: []
})

export const SET_CAMP_MEMBER_CHURCHES = 'SET_CAMP_MEMBER_CHURCHES'
export const SET_CAMP_PROFILE = 'SET_CAMP_PROFILE'
export const SET_CURRENT_CAMP = 'SET_CURRENT_CAMP'
export const SET_FORMATTED_PROFILE = 'SET_FORMATTED_PROFILE'
export const SET_HOME_CAMP = 'SET_HOME_CAMP'
export const SET_HOME_STATE = 'SET_HOME_STATE'
export const SET_OUTSIDE_STATE_CAMP_LIST = 'SET_OUTSIDE_STATE_CAMP_LIST'
export const SET_STATE_CAMP_LIST = 'SET_STATE_CAMP_LIST'
export const SET_STATE_LEVEL = 'SET_STATE_LEVEL'
export const SET_STATE_OR_CAMP = 'SET_STATE_OR_CAMP'
export const SET_STATE_UNDIST = 'SET_STATE_UNDIST'
export const SET_WSM_DATA = 'SET_WSM_DATA'

const apiCalls = {
  // eslint-disable-next-line camelcase
  getStateCamps(org_key) {
    const route = API_CONFIG.getStateCamps(org_key)
    return axios.get(route)
  },
  getStateData(org_key) {
    const route = API_CONFIG.getStateData(org_key)
    return axios.get(route)
  },
  getWSMData(org_key, lang_key) {
    const route = API_CONFIG.getWSMData(org_key, lang_key)
    return axios.get(route)
  },
  getCampMemberChurches(orgKey) {
    const route = API_CONFIG.getCampMemberChurches(orgKey)
    return axios.get(route)
  },
  getCampProfile(orgKey) {
    const route = API_CONFIG.getCampProfile(orgKey)
    return axios.get(route)
  },
  upsertWSM(data) {
    const route = API_CONFIG.upsertWSM()
    return axios.post(route, data)
  },
  editCampProfile(data) {
    const route = API_CONFIG.editCampProfile()
    return axios.post(route, data)
  }
}

export const actions = {
  async getStateData({ commit, dispatch }, orgid) {
    try {
      if (orgid.length > 0) {
        const response = await apiCalls.getStateData(orgid)
        if (response && response.status === 200) {
          await commit('SET_HOME_STATE', response.data)
          await commit('SET_STATE_LEVEL', {
            area: '',
            area_key: '',
            camp_name: response.data.display_text,
            camp_number: response.data.state_code,
            cmp_org_key: response.data.value,
            region: '',
            region_key: '',
            visiturl: null,
            zone: '',
            zone_key: ''
          })
          await dispatch
          return true
        }
      }
    } catch (error) {
      return false
    }
  },
  async addOutsideStateCampToCampList({ commit, state }, camp) {
    await commit('SET_STATE_CAMP_LIST', [
      ...state.stateCampList,
      ...[
        {
          area: '',
          area_key: '',
          camp_name: camp.name,
          camp_number: camp.number,
          cmp_org_key: camp.key,
          region: '',
          region_key: '',
          visiturl: null,
          zone: '',
          zone_key: ''
        }
      ]
    ])
  },
  async getCampsFromOutsideState({ commit }, orgid) {
    if (orgid.length > 0) {
      const response = await apiCalls.getStateCamps(orgid)
      if (response && response.status === 200) {
        await commit(
          'SET_OUTSIDE_STATE_CAMP_LIST',
          response.data.filter(cl => {
            return !cl.camp_number.includes('990')
          })
        )
      }
    }
  },
  async getCamps({ commit, dispatch }, orgid) {
    try {
      if (orgid.length > 0) {
        const response = await apiCalls.getStateCamps(orgid)
        if (response && response.status === 200) {
          await commit(
            'SET_STATE_CAMP_LIST',
            response.data.filter(cl => {
              return !cl.camp_number.includes('990')
            })
          )
          await commit(
            'SET_STATE_UNDIST',
            response.data.find(cl => {
              return cl.camp_number.includes('990')
            })
          )
          await dispatch('getStateData', orgid)
          return true
        }
      }
    } catch (error) {
      return false
    }
  },
  async unsetStateUndist({ commit }) {
    commit('SET_STATE_UNDIST', {})
  },
  async setFormattedProfile({ commit }, data) {
    commit('SET_FORMATTED_PROFILE', data)
  },
  async setStateOrCamp({ commit }, sc) {
    commit('SET_STATE_OR_CAMP', sc)
  },
  async getWSMData({ commit, rootGetters }, orgKey) {
    let langKey = rootGetters['user/userLanguageKey']
    const response = await apiCalls.getWSMData(orgKey, langKey)

    if (response && response.status === 200) {
      await commit('SET_WSM_DATA', response.data)
    }
  },
  async getMemberChurches({ commit, rootGetters }, orgKey) {
    const response = await apiCalls.getCampMemberChurches(orgKey)

    if (response && response.status === 200) {
      await commit('SET_CAMP_MEMBER_CHURCHES', response.data)
    }
  },
  async upsertWSM({ commit, rootGetters }, data) {
    const response = await apiCalls.upsertWSM(data)
    if (response && response.status === 200) {
      return true
    }
    return false
  },
  async editCampProfile({ }, data) {
    const response = await apiCalls.editCampProfile(data)
    if (response && response.status === 200) {
      return true
    }
    return false
  },
  async getCampProfile({ commit, rootGetters }) {
    let orgKey = rootGetters['user/userCampKey']
    const response = await apiCalls.getCampProfile(orgKey)

    if (response && response.status === 200) {
      await commit('SET_CAMP_PROFILE', response.data)
    }
  },
  async setHomeCamp({ commit, dispatch }, camp) {
    await commit('SET_HOME_CAMP', camp)
    await commit('SET_CURRENT_CAMP', camp)
    dispatch('accountModule/getAccount', camp.key, {
      root: true
    })
    return true
  },
  async setCurrentCamp({ commit }, camp) {
    commit('SET_CURRENT_CAMP', camp)
    return true
  },
  async setCurrentCampByKey({ state, commit, dispatch }, campKey) {
    let cp = [...state.stateCampList, ...[state.stateLevel], ...[!!state.stateUndist ? state.stateUndist : '']]
      .filter(cmp => {
        return cmp.cmp_org_key === campKey
      })
      .map(co => {
        return {
          key: co.cmp_org_key,
          text: co.camp_name,
          value: co.camp_number
        }
      })[0]
    await commit('SET_CURRENT_CAMP', cp)
    // await dispatch('resetHomeCamp') // this is a patch; somehow the homeCamp.key is being set to the currentCamp key
    return true
  },
  async setHomeState({ commit }, homeState) {
    commit('SET_HOME_STATE', homeState)
    return true
  },
  async resetHomeCamp({ commit, rootGetters, state }) {
    const campkey =
      state.state_or_camp === 's' && state.stateLevel.camp_number === state.homeCamp.value
        ? state.stateLevel.cmp_org_key
        : state.state_or_camp === 's' && state.stateUndist.camp_number === state.homeCamp.value
          ? state.stateUndist.cmp_org_key
          : await rootGetters['user/userCampKey']
    const rawcamp = [...state.stateCampList, ...[state.stateLevel], ...[state.stateUndist]].filter(
      camp => camp.cmp_org_key === campkey
    )[0]
    await commit('SET_HOME_CAMP', {
      key: rawcamp.cmp_org_key,
      value: rawcamp.camp_number,
      text: rawcamp.camp_name
    })
    return true
  }
}

export const getters = {
  allCamps: state => {
    return [...state.stateCampList, ...[state.stateLevel], ...[state.stateUndist]]
  },
  campByNumber: state => campNumber => {
    return [...state.stateCampList, ...[state.stateLevel], ...[state.stateUndist]].filter(
      camp => camp.camp_number === campNumber
    )[0]
  },
  campByKey: state => campKey => {
    return [...state.stateCampList, ...[state.stateLevel], ...[state.stateUndist]].filter(
      camp => camp.cmp_org_key.toLowerCase() === campKey.toLowerCase()
    )[0]
  },
  campList: state => {
    const scl = state.stateCampList.map(cmp => {
      return {
        text: '(' + cmp.camp_number + ') ' + cmp.camp_name,
        value: cmp.cmp_org_key
      }
    })
    return state.state_or_camp === 's'
      ? [
        ...scl,
        ...[
          {
            text: '(' + state.stateUndist.camp_number + ') ' + state.stateUndist.camp_name,
            value: state.stateUndist.cmp_org_key
          }
        ]
      ]
      : scl
  },
  campListByCampNum: state => {
    let orderedStateCampList = [...state.stateCampList].sort((a, b) => a.camp_number.localeCompare(b.camp_number, "en", { numeric: true, sensitivity: 'base' }))
    const scl = orderedStateCampList.map(cmp => {
      return {
        text: '(' + cmp.camp_number + ') ' + cmp.camp_name,
        value: cmp.cmp_org_key
      }
    })
    return state.state_or_camp === 's'
      ? [
        ...scl,
        ...[
          {
            text: '(' + state.stateUndist.camp_number + ') ' + state.stateUndist.camp_name,
            value: state.stateUndist.cmp_org_key
          }
        ]
      ]
      : scl
  },
  campListWithState: state => {
    return [...state.stateCampList, ...[state.stateLevel]].map(cmp => {
      return {
        text: '(' + cmp.camp_number + ') ' + cmp.camp_name,
        value: cmp.cmp_org_key
      }
    })
  },
  completeOutsideCampObject: state => oco => {
    let match = state.outsideStateCampList.find(u => u.cmp_org_key.toLowerCase() === oco.key.toLowerCase())
    oco.name = match.camp_name
    oco.number = match.camp_number
    return oco
  },
  currentCamp: state => {
    return state.currentCamp
  },
  homeCamp: state => {
    return state.homeCamp
  },
  homeState: state => {
    return state.homeState
  },
  outsideStateCampList: state => {
    return state.outsideStateCampList.map(cmp => {
      return {
        text: '(' + cmp.camp_number + ') ' + cmp.camp_name,
        value: cmp.cmp_org_key
      }
    })
  },
  stateCampList: state => {
    return state.stateCampList
  },
  stateOrCamp: state => {
    return state.state_or_camp
  },
  stateLevel: state => {
    return state.stateLevel
  },
  stateUndist: state => {
    return state.stateUndist
  },
  wsmData: state => {
    return state.wsmData
  },
  campMemberChurches: state => {
    return state.campMemberChurches
  },
  campProfile: state => {
    return state.campProfile
  },
  formattedProfile: state => {
    return state.formattedProfile
  }
}
export const mutations = {
  [SET_STATE_CAMP_LIST](state, data) {
    state.stateCampList = data || []
  },
  [SET_OUTSIDE_STATE_CAMP_LIST](state, data) {
    state.outsideStateCampList = data || []
  },
  [SET_STATE_LEVEL](state, data) {
    state.stateLevel = data
  },
  [SET_STATE_UNDIST](state, data) {
    state.stateUndist = data
  },
  [SET_CURRENT_CAMP](state, data) {
    state.currentCamp = data
  },
  [SET_FORMATTED_PROFILE](state, data) {
    state.formattedProfile = data
  },
  [SET_HOME_CAMP](state, data) {
    state.homeCamp = data
  },
  [SET_HOME_STATE](state, data) {
    state.homeState = data
    state.homeState.value = !!state.homeState ? state.homeState.value : ''
  },
  [SET_STATE_OR_CAMP](state, data) {
    state.state_or_camp = data
  },
  [SET_WSM_DATA](state, data) {
    state.wsmData = data
  },
  [SET_CAMP_MEMBER_CHURCHES](state, data) {
    state.campMemberChurches = data
  },
  [SET_CAMP_PROFILE](state, data) {
    state.campProfile = data
  }
}

export const campModule = {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}
