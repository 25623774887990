/* eslint-disable */
export const API_ACTIVE_ROUTE = process.env.VUE_APP_API_ROUTE_ADDRESS
export const API_REPORT_ACTIVE_ROUTE = process.env.VUE_APP_REPORT_API_ROUTE_ADDRESS

export const API_CONFIG = {
  addPrayerCalendar: org_key => `${API_ACTIVE_ROUTE}/prayer/calendar/add/${org_key}`,
  addPrayerRequest: org_key => `${API_ACTIVE_ROUTE}/prayer/calendar/request/item/add/${org_key}`,
  addUpdateAnnouncementMessage: () => `${API_ACTIVE_ROUTE}/announcement/message`,
  deleteAnnouncementMessage: () => `${API_ACTIVE_ROUTE}/announcement/message/delete`,
  deletePendingTransaction: (org_key, rmt_key, ind_key) => `${API_ACTIVE_ROUTE}/remittance/detail/delete/${rmt_key}/${org_key}/${ind_key}`,
  deletePrayerCalendar: (org_key, key) => `${API_ACTIVE_ROUTE}/prayer/calendar/delete/${org_key}?key=${key}`,
  deletePrayerRequest: item_key => `${API_ACTIVE_ROUTE}/prayer/calendar/request/item/special/delete/${item_key}`,
  deleteRemittance: (rmt_key, ind_key) => `${API_ACTIVE_ROUTE}/remittance/delete/${rmt_key}/${ind_key}`,
  deleteUploadedFile: (bau_key, bac_year, ind_key) => `${API_ACTIVE_ROUTE}/remittance/bankingactivityfile/delete/${bau_key}/${bac_year}/${ind_key}`,
  editCampProfile: () => `${API_ACTIVE_ROUTE}/campProfile/info/update/`,
  extendUserLogin: () => `${API_ACTIVE_ROUTE}/api/account/ExtendLoginAuthentication`,
  generatePrayer: key => `${API_ACTIVE_ROUTE}/prayer/calendar/generate/${key}`,
  getACRSpeakers: (org_key, lang_key, acr_type) => `${API_ACTIVE_ROUTE}/GideonDirectory/Speakers/${org_key}/${lang_key}/${acr_type}`,
  getAddableCommittees: (ind_key, lng_key) => `${API_ACTIVE_ROUTE}/announcements/addable/${ind_key}/${lng_key}`,
  getAllowedControls: (ind_key, account_id, nag_key) => `${API_ACTIVE_ROUTE}/api/account/AllowedControls/${ind_key}/${account_id}/${nag_key}`,
  getAnnouncementForCommittee: (cmt_key, ind_key) => `${API_ACTIVE_ROUTE}/announcement/getForCommittee/${cmt_key}/${ind_key}`,
  getAnnouncementForState: state_key => `${API_ACTIVE_ROUTE}/announcement/getForState/${state_key}`,
  getAnnouncementMessages: () => `${API_ACTIVE_ROUTE}/announcements`,
  getAnnouncementsForCamp: org_key => `${API_ACTIVE_ROUTE}/announcements/camp/${org_key}`,
  getAnnouncementsForEdit: () => `${API_ACTIVE_ROUTE}/announcements/edit`,
  getAreaReportUrls: (zonelvlcode, statelvlcode, area, areaReport, areaYear) => `${API_ACTIVE_ROUTE}/report/secure/areareporturls/${zonelvlcode}/${statelvlcode}/${area}/${areaReport}/${areaYear}`,
  getAreaReportYears: (zonelvlcode, statelvlcode, area, areaReport) => `${API_ACTIVE_ROUTE}/report/secure/areareportyears/${zonelvlcode}/${statelvlcode}/${area}/${areaReport}`,
  getAreas: (org_key, org_ogt_key, lng_key) => `${API_ACTIVE_ROUTE}/report/secure/areas/${org_key}/${org_ogt_key}/${lng_key}`,
  getAssociationReportUrls: (nationallvlcode, nationalYear) => `${API_ACTIVE_ROUTE}/report/secure/nationalurls/${nationallvlcode}/${nationalYear}`,
  getAssociationReportYears: nationallvlcode => `${API_ACTIVE_ROUTE}/report/secure/nationalyears/${nationallvlcode}`,
  getAssociations: (org_key, org_ogt_key, lng_key) => `${API_ACTIVE_ROUTE}/report/secure/associations/${org_key}/${org_ogt_key}/${lng_key}`,
  getAuthQuestions: () => `${API_ACTIVE_ROUTE}/api/account/GetAuthQuestions`,
  getAuxiliaryCandidates: (camp_key, lng_key) => `${API_ACTIVE_ROUTE}/auxiliary/getauxiliarycandidates/${camp_key}/${lng_key}`,
  getAuxiliaryMembers: (camp_key, lng_key) => `${API_ACTIVE_ROUTE}/auxiliary/getauxiliarymembers/${camp_key}/${lng_key}`,
  getAuxiliaryMembersAll: (camp_key, lng_key) => `${API_ACTIVE_ROUTE}/auxiliary/getauxiliarymembersall/${camp_key}/${lng_key}`,
  getAuxiliaryMembersNew: (camp_key, lng_key) => `${API_ACTIVE_ROUTE}/auxiliary/getauxiliarymembersnew/${camp_key}/${lng_key}`,
  getAuxiliaryOfficers: (camp_key, lng_key) => `${API_ACTIVE_ROUTE}/auxiliary/getauxiliaryofficers/${camp_key}/${lng_key}`,
  getAuxiliaryPageWidgetData: org_key => `${API_ACTIVE_ROUTE}/auxiliary/getauxiliary/${org_key}`,
  getAuxiliaryPlacementDatesByCampLast12: (camp_key, lng_key) => `${API_ACTIVE_ROUTE}/auxiliary/getauxiliaryplacementdatesbycamplast12/${camp_key}/${lng_key}`,
  getAuxiliaryPresentations: org_key => `${API_ACTIVE_ROUTE}/auxiliary/getauxiliarypresentations/${org_key}/`,
  getAvailableMonths: () => `${API_ACTIVE_ROUTE}/remittance/bankingactivity/listing`,
  getBankAccount: org_key => `${API_ACTIVE_ROUTE}/remittance/bankaccount/${org_key}`,
  getCalendarItemImage: (lang_key, path) => `${API_ACTIVE_ROUTE}/media/${lang_key}/images/PrayerCalendar/${path}`,
  getCampActivities: org_key => `${API_ACTIVE_ROUTE}/remittance/camp/activity/${org_key}`,
  getCampMemberChurches: org_key => `${API_ACTIVE_ROUTE}/member/churches/${org_key}`,
  getCampOfficerPositions: (org_key, lang_key) => `${API_ACTIVE_ROUTE}/GideonDirectory/CampOfficerPositions/${org_key}/${lang_key}/`,
  getCampOfficerSelections: (org_key, ctp_key, lang_key) => `${API_ACTIVE_ROUTE}/GideonDirectory/CampOfficerSelections/${org_key}/${ctp_key}/${lang_key}/`,
  getCampProfile: org_key => `${API_ACTIVE_ROUTE}/campProfile/info/${org_key}`,
  getCamps: (ind_key, lng_key, ogt_key, org_key) => `${API_ACTIVE_ROUTE}/report/secure/camps/${ind_key}/${lng_key}/${ogt_key}/${org_key}`,
  getCampsByStateArea: (rpd_key, site_key, ind_key, lng_key) => `${API_ACTIVE_ROUTE}/report/parameters/campsbyarea/${rpd_key}/${site_key}/${ind_key}/${lng_key}`,
  getCampStateData: () => `${API_ACTIVE_ROUTE}/remittance/camp/state`,
  getCampUrls: (ind_key, org_key, is_posted_date_view_flag, report_type, lng_key) => `${API_ACTIVE_ROUTE}/report/secure/campurls/${ind_key}/${org_key}/${is_posted_date_view_flag}/${report_type}/${lng_key}`,
  getCategoryTypes: org_key => `${API_ACTIVE_ROUTE}/prayer/calendar/types/categories/${org_key}`,
  getControlPermissions: (lng_key, ctl_key) => `${API_ACTIVE_ROUTE}/api/account/control/permissions/${lng_key}/${ctl_key}`,
  getControlPermissionsFromList: () => `${API_ACTIVE_ROUTE}/api/account/control/permissions`,
  getCountries: (ind_key, lng_key, ogt_key, org_key) => `${API_ACTIVE_ROUTE}/report/secure/countries/${ind_key}/${lng_key}/${ogt_key}/${org_key}`,
  getDailyPrayerData: (org_key, date, lng) => `${API_ACTIVE_ROUTE}/prayer/calendar/summarybyorg/${org_key}/${date}/${lng}`,
  getDataForEditingAnnouncement: msg_key => `${API_ACTIVE_ROUTE}/announcement/getForEdit/${msg_key}`,
  getDetailsByKey: (org_key, date, rmt_key, multicamp, status) => `${API_ACTIVE_ROUTE}/remittance/detailbydate/?org_key=${org_key}&rmt_date=${date}&multicamp=${multicamp}&rmt_key=${rmt_key}&status=${status}`,
  getEditablePrayerListing: key => `${API_ACTIVE_ROUTE}/prayer/calendar/${key}/editable/list`,
  getElectionAuxiliaryMembers: (camp_key, lng_key) => `${API_ACTIVE_ROUTE}/camp/election/getauxiliarymembers/${camp_key}/${lng_key}`,
  getElectionCommittee: (camp_key, lng_key) => `${API_ACTIVE_ROUTE}/camp/election/getcommittee/${camp_key}/${lng_key}`,
  getElectionCommitteeMembers: (camp_key, lng_key) => `${API_ACTIVE_ROUTE}/camp/election/getcommitteememberdetails/${camp_key}/${lng_key}`,
  getElectionGideonMembers: (camp_key, lng_key) => `${API_ACTIVE_ROUTE}/camp/election/getgideonmembers/${camp_key}/${lng_key}`,
  getEncounterDetailsByKey: (wit_key, cmp_key, lnd_key) => `${API_ACTIVE_ROUTE}/prayer/ppw/opportunity/${wit_key}/${cmp_key}/${lnd_key}`,
  getFilteredTestimonies: (lang, rows, search, filter) => `${API_ACTIVE_ROUTE}/testimony/filteredsearch/${lang}/${rows}/${search}/${filter}`,
  getFiscalYears: () => `${API_ACTIVE_ROUTE}/remittance/bankingactivity/periods`,
  getIndividualTestimonyType: ind_key => `${API_ACTIVE_ROUTE}/GideonDirectory/GetIndividualTestimonyType/${ind_key}`,
  getMembersAuthQuestionsAndAnswers: memberno => `${API_ACTIVE_ROUTE}/api/account/GetMembersAuthQuestionsAndAnswers/${memberno}`,
  getMembersList: (org_key, lang_key) => `${API_ACTIVE_ROUTE}/GideonDirectory/MembersList/${org_key}/${lang_key}/`,
  getMonthlyCamps: org_key => `${API_ACTIVE_ROUTE}/report/secure/monthlycamps/${org_key}`,
  getMonthlyStates: (org_key, org_ogt_key, lng_key) => `${API_ACTIVE_ROUTE}/report/secure/monthlystates/${org_key}/${org_ogt_key}/${lng_key}`,
  getMonthlyUrls: (statelvlcode, camplvlcode, monthlyYear) => `${API_ACTIVE_ROUTE}/report/secure/monthlyurls/${statelvlcode}/${camplvlcode}/${monthlyYear}`,
  getMonthlyYears: (statelvlcode, camplvlcode) => `${API_ACTIVE_ROUTE}/report/secure/monthlyyears/${statelvlcode}/${camplvlcode}`,
  getMonthPrayerRequest: (cpr_key, org_key) => `${API_ACTIVE_ROUTE}/prayer/calendar/${cpr_key}/request/item?org_key=${org_key}`,
  getNationalUrls: (ind_key, org_key, is_posted_date_view_flag, report_type, lng_key) => `${API_ACTIVE_ROUTE}/report/secure/nationalurls/${ind_key}/${org_key}/${is_posted_date_view_flag}/${report_type}/${lng_key}`,
  getOfficerToolbarCamps: (state_country, ind_key) => `${API_ACTIVE_ROUTE}/api/account/officer/toolbar/camps/${state_country}/${ind_key}`,
  getOfficerToolbarCountriesStates: (zone, ind_key, SPSP) => `${API_ACTIVE_ROUTE}/api/account/officer/toolbar/statescountries/${zone}/${ind_key}/${SPSP}`,
  getOfficerToolbarRegionsZones: (ind_key, SPSP) => `${API_ACTIVE_ROUTE}/api/account/officer/toolbar/regionszones/${ind_key}/${SPSP}`,
  getOpportunities: (org_key, lng_key, most_recent, ind_key, lang) => `${API_ACTIVE_ROUTE}/prayer/ppw/opportunities?cmp_key=${org_key}&lng_key=${lng_key}&most_recent=${most_recent}&ind_key=${ind_key}&lang=${lang}`,
  getPendingDetailsByKey: (org_key, date, multicamp, rmt_key) => `${API_ACTIVE_ROUTE}/remittance/pendingdetailbydate/?org_key=${org_key}&rmt_date=${date}&multicamp=${multicamp}&rmt_key=${rmt_key}`,
  getPendingRemittances: org_key => `${API_ACTIVE_ROUTE}/remittance/pending/${org_key}`,
  getPendingRemittancesByDate: (org_key, date, multicamp) => `${API_ACTIVE_ROUTE}/remittance/pendingdetailbydate/?org_key=${org_key}&rmt_date=${date}&multicamp=${multicamp}`,
  getPendingSummaryByOrg: (org_key) => `${API_ACTIVE_ROUTE}/remittance/pendingcompletedsummarybyorg?org_key=${org_key}`,
  getPrayerListing: key => `${API_ACTIVE_ROUTE}/prayer/calendar/${key}/list`,
  getQualifiedSpeakers: (org_key, lang_key) => `${API_ACTIVE_ROUTE}/GideonDirectory/QualifiedSpeakers/${org_key}/${lang_key}/`,
  getRegionalCommittees: () => `${API_ACTIVE_ROUTE}/regional/committees`,
  getRegions: (org_key, org_ogt_key, lng_key) => `${API_ACTIVE_ROUTE}/report/secure/regions/${org_key}/${org_ogt_key}/${lng_key}`,
  getRegionUrls: (regionlvlcode, reportlvlcode, reportYear) => `${API_ACTIVE_ROUTE}/report/secure/regionurls/${regionlvlcode}/${reportlvlcode}/${reportYear}`,
  getRegionYears: (regionlvlcode, reportlvlcode) => `${API_ACTIVE_ROUTE}/report/secure/regionyears/${regionlvlcode}/${reportlvlcode}`,
  getRemittanceAnnouncement: () => `${API_ACTIVE_ROUTE}/remittance/announcement`,
  getRemittanceHistoryByDate: (org_key, start_date, end_date) => `${API_ACTIVE_ROUTE}/remittance/all/${org_key}/${start_date}/${end_date}`,
  getRemittanceHistoryTop10: org_key => `${API_ACTIVE_ROUTE}/remittance/top10/${org_key}`,
  getReportParameters: (rpt_key, org_key, ind_key, lng_key) => `${API_ACTIVE_ROUTE}/report/parameters/${rpt_key}/${org_key}/${ind_key}/${lng_key}`,
  getReports: lng_key => `${API_ACTIVE_ROUTE}/report/listing/${lng_key}`,
  getReportStream: () => `${API_ACTIVE_ROUTE}/report/stream/`,
  getReportUrls: (zonelvlcode, reportlvlcode, reportYear) => `${API_ACTIVE_ROUTE}/report/secure/reporturls/${zonelvlcode}/${reportlvlcode}/${reportYear}`,
  getReportYears: (zonelvlcode, reportlvlcode) => `${API_ACTIVE_ROUTE}/report/secure/reportyears/${zonelvlcode}/${reportlvlcode}`,
  getScriptureBlitzSpeakers: (org_key, lang_key) => `${API_ACTIVE_ROUTE}/GideonDirectory/ScriptureBlitzSpeakers/${org_key}/${lang_key}/`,
  getSpecialPrayerRequest: org_key => `${API_ACTIVE_ROUTE}/prayer/calendar/request/special/org/${org_key}`,
  getStateActivities: org_key => `${API_ACTIVE_ROUTE}/remittance/state/activity/${org_key}`,
  getStateCamp: (org_key, lang_key) => `${API_ACTIVE_ROUTE}/GideonDirectory/StateCamp/${org_key}/${lang_key}/`,
  getStateCamps: org_key => `${API_ACTIVE_ROUTE}/remittance/statecamps/${org_key}`,
  getStateData: org_key => `${API_ACTIVE_ROUTE}/remittance/state/${org_key}`,
  getStateReportUrls: (zonelvlcode, statelvlcode, stateReport, stateYear) => `${API_ACTIVE_ROUTE}/report/secure/statereporturls/${zonelvlcode}/${statelvlcode}/${stateReport}/${stateYear}`,
  getStateReportYears: (zonelvlcode, statelvlcode, stateReport) => `${API_ACTIVE_ROUTE}/report/secure/statereportyears/${zonelvlcode}/${statelvlcode}/${stateReport}`,
  getStates: (org_key, org_ogt_key, lng_key) => `${API_ACTIVE_ROUTE}/report/secure/states/${org_key}/${org_ogt_key}/${lng_key}`,
  getSuccessStories: (lng_key, rows, art_key) => `${API_ACTIVE_ROUTE}/successStory/${lng_key}/${rows}/${art_key}`,
  getSuccessStoriesNoKey: (lng_key, rows) => `${API_ACTIVE_ROUTE}/successStory/${lng_key}/${rows}`,
  getSummaryByOrg: (org_key, status, rmt_key) => `${API_ACTIVE_ROUTE}/remittance/summarybyorg?org_key=${org_key}&status=${status}&rmt_key=${rmt_key}`,
  getTestimonies: (lng_key, rows, art_key) => `${API_ACTIVE_ROUTE}/testimony/${lng_key}/${rows}/${art_key}`,
  getTestimoniesNoKey: (lng_key, rows) => `${API_ACTIVE_ROUTE}/testimony/${lng_key}/${rows}`,
  getTestimonySpeakers: (org_key, lang_key) => `${API_ACTIVE_ROUTE}/GideonDirectory/TestimonySpeakers/${org_key}/${lang_key}/`,
  getTestimonyTypes: lang_key => `${API_ACTIVE_ROUTE}/GideonDirectory/GetTestimonyTypes/${lang_key}`,
  getTestimonyWithKey: (art_key, lng_key) => `${API_ACTIVE_ROUTE}/testimony/key/${art_key}/${lng_key}`,
  getUploads: (org_key, bac_year) => `${API_ACTIVE_ROUTE}/remittance/bankingactivityfile/listing/${org_key}/${bac_year}`,
  getUserData: ind_key => `${API_ACTIVE_ROUTE}/remittance/user/${ind_key}`,
  getWidowsList: (org_key, lang_key) => `${API_ACTIVE_ROUTE}/GideonDirectory/WidowsList/${org_key}/${lang_key}/`,
  getWorldUrls: (reportlvlcode, reportYear) => `${API_ACTIVE_ROUTE}/report/secure/worldurls/${reportlvlcode}/${reportYear}`,
  getWorldYears: reportlvlcode => `${API_ACTIVE_ROUTE}/report/secure/worldyears/${reportlvlcode}`,
  getWSMData: (org_key, lang_key) => `${API_ACTIVE_ROUTE}/websitesecurity/manager/${org_key}/${lang_key}`,
  getZones: (org_key, org_ogt_key, lng_key) => `${API_ACTIVE_ROUTE}/report/secure/zones/${org_key}/${org_ogt_key}/${lng_key}`,
  processDonationPayment: () => `${API_ACTIVE_ROUTE}/donation/processDonationPayment`,
  processPreauth: () => `${API_ACTIVE_ROUTE}/remittance/processpreauth/`,
  processRemittance: (rmt_key, ind_key) => `${API_ACTIVE_ROUTE}/remittance/process/${rmt_key}/${ind_key}`,
  recordFeedback: ind_key => `${API_ACTIVE_ROUTE}/feedback/${ind_key}`,
  registerUser: () => `${API_ACTIVE_ROUTE}/api/account/Register`,
  resetUserPassword: () => `${API_ACTIVE_ROUTE}/api/account/ResetPasswordByAuthQuestionAndAnswer`,
  resubmitRemittance: (rmt_key, ind_key) => `${API_ACTIVE_ROUTE}/remittance/ResubmitRemittance/${rmt_key}/${ind_key}`,
  savePendingTransaction: () => `${API_ACTIVE_ROUTE}/remittance/update`,
  saveTestimony: () => `${API_ACTIVE_ROUTE}/testimony/savetestimony`,
  searchArticles: (search_for, art_type, rows, lng_key) => `${API_ACTIVE_ROUTE}/information/article/search/${search_for}/${art_type}/${rows}/${lng_key}`,
  updateBankAccount: () => `${API_ACTIVE_ROUTE}/remittance/bankaccount/update`,
  updateBankingActivity: () => `${API_ACTIVE_ROUTE}/remittance/bankingactivity/update`,
  updateCalendarItem: org_key => `${API_ACTIVE_ROUTE}/prayer/calendar/request/item/update/${org_key}`,
  updateElectionCommittee: () => `${API_ACTIVE_ROUTE}/camp/election/updatecommittee`,
  updateEncounter: () => `${API_ACTIVE_ROUTE}/prayer/ppw/opportunities/save`,
  updatePrayerCalendar: (cpr_key, name, active, user, year) => `${API_ACTIVE_ROUTE}/prayer/calendar/calendar/update/${cpr_key}?name=${name}&active=${active}&user=${user}&year=${year}`,
  updatePrayerRequest: org_key => `${API_ACTIVE_ROUTE}/prayer/calendar/request/special/update/${org_key}`,
  uploadCalendarItemImage: org_key => `${API_ACTIVE_ROUTE}/prayer/calendar/upload/image/${org_key}`,
  uploadFile: (bac_key, ind_key, staff_flag) => `${API_ACTIVE_ROUTE}/remittance/bankingactivityfile/upload/${bac_key}/${ind_key}/${staff_flag}`,
  uploadPrayerCalendarCSV: (cpr_key, ind_key) => `${API_ACTIVE_ROUTE}/prayer/calendar/upload/items/${cpr_key}/${ind_key}`,
  upsertACRSpeakers: acr_type => `${API_ACTIVE_ROUTE}/GideonDirectory/Speakers/upsert/${acr_type}`,
  upsertElectionCommitteeMember: () => `${API_ACTIVE_ROUTE}/camp/election/upsertmembers`,
  upsertTestimonySpeaker: () => `${API_ACTIVE_ROUTE}/GideonDirectory/UpsertTestimony/`,
  upsertWSM: () => `${API_ACTIVE_ROUTE}/websitesecurity/manager/upsert`,
  userImpersonation: memberno => `${API_ACTIVE_ROUTE}/api/account/Impersonate/${memberno}`,
  userLogin: () => `${API_ACTIVE_ROUTE}/api/account/Login`,
  userLoginTwoFactor: rndnum => `${API_ACTIVE_ROUTE}/api/account/LoginUserTwoFactor?rndnum=${rndnum}`,
  userPurview: ind_key => `${API_ACTIVE_ROUTE}/prayer/calendar/user/purview/${ind_key}`,
  verifyAccountExists: (memberno, campno) => `${API_ACTIVE_ROUTE}/api/account/VerifyAccountExists/${memberno}/${campno}`,
  verifyAuthQuestionAndAnswer: () => `${API_ACTIVE_ROUTE}/api/account/VerifyAuthQuestionAndAnswer`
}
