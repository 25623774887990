/* eslint-disable */
import { API_CONFIG } from '@/config/api.config'
import { parseFieldSpecs } from '@fullcalendar/core'
import axios from 'axios'

export const state = () => ({
  completedDetails: [],
  completedSummaryForOrg: [],
  multiRemittance: {
    date: '',
    edit: false,
    rmt_key: '',
    total: 0,
    field: { text: '', value: '' },
    camp: []
  },
  multicampRemittanceItems: [
    {
      text: 'tcAnnualPastorsEvent',
      value: 'ff_annual_pastor_event',
      stateCon: 0,
      amount: 0,
      count: 0,
      countText: '',
      countField: ''
    },
    {
      text: 'tcFaithFundRally',
      value: 'ff_area_mtg',
      stateCon: 0,
      amount: 0,
      count: 0,
      countText: '',
      countField: ''
    },
    {
      text: 'tcSummerFaithFundRallies',
      value: 'ff_int_conv_gid',
      stateCon: 0,
      amount: 0,
      count: 0,
      countText: '',
      countField: ''
    },
    {
      text: 'tcSummerFaithFundRalliesAux',
      value: 'ff_int_conv_rally_aux',
      stateCon: 0,
      amount: 0,
      count: 0,
      countText: '',
      countField: ''
    },
    {
      text: 'tcAuxiliaryEventFaithFund',
      value: 'ff_aux_evt',
      stateCon: 0,
      amount: 0,
      count: 0,
      countText: '',
      countField: ''
    },
    {
      text: 'tcFridayEveningBanquetFaithFund',
      value: 'ff_fri_eve_banquet',
      stateCon: 1,
      amount: 0,
      count: 0,
      countText: '',
      countField: ''
    },
    {
      text: 'tcPresBreakfastCountdown100',
      value: 'ff_sta_conv_cdn',
      stateCon: 1,
      amount: 0,
      count: 0,
      countText: '',
      countField: ''
    },
    {
      text: 'tcAuxiliaryBreakfastHeartProgram',
      value: 'ff_sta_conv_aux',
      stateCon: 1,
      amount: 0,
      count: 0,
      countText: '',
      countField: ''
    },
    {
      text: 'tcAuxiliaryLuncheon',
      value: 'ff_aux_luncheon',
      stateCon: 1,
      amount: 0,
      count: 0,
      countText: '',
      countField: ''
    },
    {
      text: 'tcSaturdayLuncheonFaithFund',
      value: 'ff_sat_luncheon',
      stateCon: 1,
      amount: 0,
      count: 0,
      countText: '',
      countField: ''
    },
    {
      text: 'tcSaturdayLuncheonBarnabasFund',
      value: 'bf_sat_luncheon',
      stateCon: 1,
      amount: 0,
      count: 0,
      countText: '',
      countField: ''
    },
    {
      text: 'tcSaturdayEveningBanquetFaithFund',
      value: 'ff_sat_eve_banquet',//'ff_sta_conv_cdn',//'ff_sat_eve_banquet',
      stateCon: 1,
      amount: 0,
      count: 0,
      countText: '',
      countField: ''
    },
    {
      text: 'tcSaturdayEveningBanquetBarnabasFund',
      value: 'bf_sat_eve_banquet',
      stateCon: 1,
      amount: 0,
      count: 0,
      countText: '',
      countField: ''
    },
  ],
  pendingItems: [],
  multicampPendingItems: [],
  pendingDetails: [],
  pendingModelNames: [
    'ch_receipt',
    'ch_presentation_num',
    'ff_cmp_ff',
    'ff_annual_pastor_event',
    'ff_area_mtg',
    'ff_aux_evt',
    'ff_sta_conv_cdn',
    'ff_sta_conv_aux',
    'ff_int_conv_gid',
    'ff_int_conv_aux',
    'gc_receipt',
    'gc_donor_num',
    'gc_presentation_gid_num',
    'gc_presentation_aux_num',
    'bj_receipt',
    'bj_church_num',
    'aux_script_fund',
    'oth_cmp_scr_fund',
    'oth_usa_out_fund',
    'bf_cmp_fund',
    'bf_sta_fund'
  ],
  pendingMultiCampModelNames: [
    'ch_receipt',
    'gc_receipt',
    'ff_annual_pastor_event',
    'ff_area_mtg',
    'ff_int_conv_gid',
    'ff_int_conv_rally_aux',
    'ff_aux_evt',
    'ff_fri_eve_banquet',
    'ff_sta_conv_cdn',
    'ff_sta_conv_aux',
    'ff_aux_luncheon',
    'ff_sat_luncheon',
    'bf_sat_luncheon',
    'ff_sat_eve_banquet',
    'bf_sat_eve_banquet'
  ],
  pendingRemittances: [],
  pendingSummaryForOrg: [],
  preauthed: false,
  rawCompletedItems: [],
  rawPendingItems: [],
  remittanceAnnouncement: [],
  remittanceHistoryItems: [],
  remittanceModel: {
    date: '',
    edit: false,
    camp: { key: '', number: '', name: '', rmd_key: '' },
    rmt_key: '',
    total: 0,
    transactions: [
      {
        group: 'tcChurch',
        groupDetails: '',
        accounts: [
          {
            amount: 0,
            category: 'tcChurchReceipts',
            field: 'ch_receipt',
            currency: true,
            class: 'cat',
            text: 'tcEnterTheTotalAmountOfDonationsReceivedFromChurchPresentationsIncludingGideoncardGiftsGivenAsAPartO',
            text2: '',
            type: 'scripture',
            order: 0
          },
          {
            amount: 0,
            category: 'tcNumberOfChurchesWithPresentations',
            field: 'ch_presentation_num',
            currency: false,
            class: 'cat',
            text: '',
            text2: '',
            type: 'scripture',
            order: 1
          }
        ]
      },
      {
        group: 'tcGideonCard',
        groupDetails: '',
        accounts: [
          {
            amount: 0,
            category: 'tcGideonCardReceipts',
            field: 'gc_receipt',
            currency: true,
            class: 'cat',
            text: 'tcEnterTheTotalAmountOfDonationsReceivedThroughTheGideoncardProgramSeparateFromChurchPresentations',
            text2: '',
            type: 'scripture',
            order: 2
          },
          {
            amount: 0,
            category: 'tcNumberOfGideonCardDonors',
            field: 'gc_donor_num',
            currency: false,
            class: 'cat',
            text: '',
            text2: '',
            type: 'scripture',
            order: 3
          },
          {
            amount: 0,
            category: 'dummyPlace',
            field: '',
            currency: false,
            class: 'cat',
            text: '',
            text2: '',
            type: '',
            order: 4
          },

          {
            amount: 0,
            category: 'tcGideonCardPresentationsGideon',
            field: 'gc_presentation_gid_num',
            currency: false,
            class: 'cat',
            text: '',
            text2: '',
            type: 'scripture',
            order: 5
          },
          {
            amount: 0,
            category: 'dummyPlace',
            field: '',
            currency: false,
            class: 'cat',
            text: '',
            text2: '',
            type: '',
            order: 6
          },
          {
            amount: 0,
            category: 'tcGideonCardPresentationsAuxiliary',
            field: 'gc_presentation_aux_num',
            currency: false,
            class: 'cat',
            text: '',
            text2: '',
            type: 'scripture',
            order: 7
          },
          {
            amount: 0,
            category: 'tcBirthdayForJesusReceipts',
            field: 'bj_receipt',
            currency: true,
            class: 'cat30',
            text: 'tcEnterTheTotalAmountReceivedOnlyThroughTheBirthdayForJesusChurchOfferingsCollectionsDoNotIncludeFun',
            text2: 'tcDoNotIncludeFundsFromBirthdayPartyForJesusCampOfferings',
            type: 'scripture',
            order: 8
          },
          {
            amount: 0,
            category: 'tcBirthdayForJesusNumberOfChurches',
            field: 'bj_church_num',
            currency: false,
            class: 'cat30',
            text: '',
            text2: '',
            type: 'scripture',
            order: 9
          }
        ]
      },
      {
        group: 'tcAuxiliaryScriptureFundDonations',
        groupDetails: '',
        accounts: [
          {
            amount: 0,
            category: 'tcAuxiliaryScriptureFunds',
            field: 'aux_script_fund',
            currency: true,
            class: 'cat',
            text: 'tcAuxiliaryScriptureFundReceiptDetails',
            text2: '',
            type: 'scripture',
            order: 10
          }
        ]
      },
      {
        group: 'tcFaithFundDonations',
        groupDetails: 'tcFaithFundDefinition',
        accounts: [
          {
            amount: 0,
            category: 'tcAnnualPastorsEvent',
            field: 'ff_annual_pastor_event',
            currency: true,
            class: 'cat',
            text: '',
            text2: '',
            type: 'scripture'
            ,
            order: 11
          },
          {
            amount: 0,
            category: 'tcInternationalConventionGideons',
            field: 'ff_int_conv_gid',
            currency: true,
            class: 'cat',
            text: 'tcSummerFaithFundRallies',
            text2: '',
            type: 'scripture',
            order: 15
          },
          {
            amount: 0,
            category: 'tcAreaFaithFundMeeting',
            field: 'ff_area_mtg',
            currency: true,
            class: 'cat',
            text: '',
            text2: '',
            type: 'scripture',
            order: 12
          },
          {
            amount: 0,
            category: 'tcInternationalConventionAuxHeartProgram',
            field: 'ff_int_conv_aux',
            currency: true,
            class: 'cat',
            text: 'tcSupportingAuxiliaryLuncheonOffering',
            text2: '',
            type: 'scripture',
            order: 16
          },
          {
            amount: 0,
            category: 'tcStateConventionCountdown100',
            field: 'ff_sta_conv_cdn',
            currency: true,
            class: 'cat',
            text: 'tcCountdown100',
            text2: '',
            type: 'scripture',
            order: 13
          },
          {
            amount: 0,
            category: 'tcAuxiliaryEvent',
            field: 'ff_aux_evt',
            currency: true,
            class: 'cat',
            text: '',
            text2: '',
            type: 'scripture',
            order: 17
          },
          {
            amount: 0,
            category: 'tcStateConventionAuxHeartProgram',
            field: 'ff_sta_conv_aux',
            currency: true,
            class: 'cat',
            text: 'tcAuxiliaryHeartProgram',
            text2: '',
            type: 'scripture',
            order: 14
          },
          {
            amount: 0,
            category: 'tcCampFaithFund',
            field: 'ff_cmp_ff',
            currency: true,
            class: 'cat',
            text: '',
            text2: '',
            type: 'scripture',
            order: 18
          },
        ]
      },
      {
        group: 'tcOtherDesignatedFundsDonations',
        groupDetails: 'tcOtherDesignatedFundsDonationsDefinition',
        accounts: [
          {
            amount: 0,
            category: 'tcCampScriptureFunds',
            field: 'oth_cmp_scr_fund',
            currency: true,
            class: 'cat',
            text: 'tcEnterTheTotalAmountOfDonationsReceivedThatAre100ForTheCampScriptureFunds',
            text2: '',
            type: 'scripture',
            order: 19
          },
          {
            amount: 0,
            category: 'tcUSAOutreachFunds',
            field: 'oth_usa_out_fund',
            currency: true,
            class: 'cat',
            text: 'tcEnterTheTotalAmountOfDonationsReceivedThatAre100ForServiceTestamentsAndLifebook',
            text2: '',
            type: 'scripture',
            order: 20
          }
        ]
      },
      {
        group: 'tcBarnabasFund',
        groupDetails: 'tcBarnabasFundDefinition',
        accounts: [
          {
            amount: 0,
            category: 'tcBFCampOfferingFund',
            field: 'bf_cmp_fund',
            currency: true,
            class: 'cat',
            text: 'tcEnterTheTotalAmountReceivedThroughCampOfferingsForTheBarnabasFund',
            text2: '',
            type: 'barnabas',
            order: 21
          },
          {
            amount: 0,
            category: 'tcBFStateConvention',
            field: 'bf_sta_fund',
            currency: true,
            class: 'cat',
            text: 'tcEnterTheTotalAmountReceivedThroughStateConventionOfferingsForTheBarnabasFund',
            text2: '',
            type: 'barnabas',
            order: 22
          }
        ]
      }
    ]
  },
  remittanceProcessedTime: 0,
  selectedSummaryItems: [],
  selectedSummaryDetails: [],
  selectedPendingItems: [],
  selectedPendingItemByFundType: [],
  selectedRemittanceKey: '',
  showData: 0,
  stateList: [],
  summaryItemsFromEntry: [],
  summaryItemsMulticampFromEntry: [],
  summaryPresentationFromEntry: [],
  theRemittanceKey: ''
})

export const getters = {
  completedSummary: state => {
    return state.completedSummaryForOrg
  },
  completedSummaryCount: state => {
    return !!state.completedSummaryForOrg ? state.completedSummaryForOrg.length : 0
  },
  fundTypeOptions: state => {
    return state.multicampRemittanceItems.filter(i => {
      return i.stateCon == 0
    })
  },
  isPreauthorized: state => {
    return state.preauthed
  },
  multicampRemittanceItems: state => {
    return state.multicampRemittanceItems
  },
  multicampPendings: state => {
    return state.multicampPendingItems || []
  },
  pendings: state => {
    return state.pendingItems || []
  },

  pendingRemittances: state => {
    return state.pendingRemittances || []
  },
  pendingSummary: state => {
    return state.pendingSummaryForOrg || []
  },
  pendingSummaryCount: state => {
    return !!state.pendingSummaryForOrg ? state.pendingSummaryForOrg.length : 0
  },
  presentationSummary: state => {
    return state.summaryPresentationFromEntry || []
  },
  remittanceAnnouncement: state => {
    return state.remittanceAnnouncement[0] || []
  },
  remittanceHistoryItems: state => {
    return state.remittanceHistoryItems || []
  },
  remittanceKey: state => {
    return state.theRemittanceKey
  },
  remittanceModel: state => {
    return state.remittanceModel
  },
  remittanceProcessTime: state => {
    return state.remittanceProcessedTime
  },

  showData: state => {
    return state.showData
  },
  selectCompletedDetails: state => (rmtKey, rmdKey) => {
    return state.completedDetails.filter(cd => {
      return (
        cd.rmt_key.toLowerCase() === rmtKey.toLowerCase() && cd.rmd_cmp_org_key.toLowerCase() === rmdKey.toLowerCase()
      )
    })
  },
  selectedSummary: state => {
    return state.selectedSummaryItems || []
  },
  selectedRemittanceKey: state => {
    return state.selectedRemittanceKey.toLowerCase()
  },
  selectPendingDetails: state => (rmtKey, rmdKey) => {
    return state.pendingDetails.filter(pd => {
      return (
        pd.rmt_key.toLowerCase() === rmtKey.toLowerCase() && pd.rmd_cmp_org_key.toLowerCase() === rmdKey.toLowerCase()
      )
    })
  },
  selectSummaryDetails: state => (rmtKey, rmdKey) => {
    return state.selectedSummaryDetails.filter(pd => {
      return (
        pd.rmt_key.toLowerCase() === rmtKey.toLowerCase() && pd.remittanceDetail.filter(rd => {
          return (
            rd.rmd_cmp_org_key.toLowerCase() === rmdKey.toLowerCase()
          )
        })
      )
    })
  },
  stateConFundTypeOptions: state => {
    return state.multicampRemittanceItems.filter(si => {
      return si.stateCon == 1
    })
  },
  outsideStateList: state => hs => {
    return state.stateList
      .map(q => {
        return {
          text: q.display_text,
          value: q.value.toLowerCase()
        }
      })
      .filter(o => o.value.toLowerCase() !== hs)
  },
  completeOutsideStateObject: state => oso => {
    let match = state.stateList.find(u => u.value.toLowerCase() === oso.key)
    oso.name = match.display_text
    oso.number = match.state_code
    return oso
  },
  transactionSummary: state => multicamp => {
    return multicamp ? state.summaryItemsMulticampFromEntry || [] : state.summaryItemsFromEntry || []
  }
}

export const EMPTY_GUID = '00000000-0000-0000-0000-000000000000'
export const SET_MULTICAMP_PENDING_ITEMS = 'SET_MULTICAMP_PENDING_ITEMS'
export const SET_PENDING_DETAILS = 'SET_PENDING_DETAILS'
export const SET_PENDING_ITEMS = 'SET_PENDING_ITEMS'
export const SET_COMPLETED_SUMMARY_FOR_ORG = 'SET_COMPLETED_SUMMARY_FOR_ORG'
export const SET_PENDING_REMITTANCES = 'SET_PENDING_REMITTANCES'
export const SET_PENDING_REMITTANCE_TIME = 'SET_PENDING_REMITTANCE_TIME'
export const SET_PENDING_SUMMARY_FOR_ORG = 'SET_PENDING_SUMMARY_FOR_ORG'
export const SET_PREAUTHORIZED_FLAG = 'SET_PREAUTHORIZED_FLAG'
export const AMEND_RAW_PENDING_ITEMS = 'AMEND_RAW_PENDING_ITEMS'
export const SET_RAW_PENDING_ITEMS = 'SET_RAW_PENDING_ITEMS'
export const SET_REMITTANCE_ANNOUNCEMENT = 'SET_REMITTANCE_ANNOUNCEMENT'
export const SET_REMITTANCE_HISTORY_ITEMS = 'SET_REMITTANCE_HISTORY_ITEMS'
export const SET_SELECTED_SUMMARY_ITEMS = 'SET_SELECTED_SUMMARY_ITEMS'
export const SET_SELECTED_PENDING_ITEMS = 'SET_SELECTED_PENDING_ITEMS'
export const SET_SELECTED_REMITTANCE_KEY = 'SET_SELECTED_REMITTANCE_KEY'
export const SET_SHOW_TABLE = 'SET_SHOW_TABLE'
export const SET_STATE_LIST = 'SET_STATE_LIST'
export const SET_SUMMARY_ITEMS = 'SET_SUMMARY_ITEMS'
export const SET_SUMMARY_MULTICAMP_ITEMS = 'SET_SUMMARY_MULTICAMP_ITEMS'
export const SET_SUMMARY_DETAILS = 'SET_SUMMARY_DETAILS'
export const SET_SUMMARY_PRESENTATIONS = 'SET_SUMMARY_PRESENTATIONS'
export const SET_THE_REMITTANCE_KEY = 'SET_THE_REMITTANCE_KEY'

export const mutations = {
  [SET_COMPLETED_SUMMARY_FOR_ORG](state, data) {
    state.completedSummaryForOrg = data
  },
  [SET_MULTICAMP_PENDING_ITEMS](state, data) {
    state.multicampPendingItems = data
  },
  [SET_PENDING_DETAILS](state, data) {
    state.pendingDetails = data
  },
  [SET_PENDING_ITEMS](state, data) {
    state.pendingItems = data
  },
  [SET_PENDING_REMITTANCES](state, data) {
    state.pendingRemittances = data
  },
  [SET_PENDING_REMITTANCE_TIME](state) {
    //Set this so users cannot submit remittances back to back. We were having an issue with NetForum setting duplicate keys for multiple entries
    const now = new Date()
    state.remittanceProcessedTime = now
  },
  [SET_PENDING_SUMMARY_FOR_ORG](state, data) {
    state.pendingSummaryForOrg = data
  },
  [SET_PREAUTHORIZED_FLAG](state, data) {
    state.preauthed = data
  },
  [SET_SHOW_TABLE](state, data) {
    state.showTable = data
  },
  [AMEND_RAW_PENDING_ITEMS](state, data) {
    state.rawPendingItems.map(eo => {
      let cd = data.find(ng => {
        return ng.cmp_key.toLowerCase() === eo.remittanceDetail[0].rmd_cmp_org_key
      })
      eo.camp_name = cd.cmp_name
      eo.camp_number = cd.cmp_number
      eo.camp_state = cd.cmp_state
    })
  },
  [SET_RAW_PENDING_ITEMS](state, data) {
    state.rawPendingItems = data
  },
  [SET_REMITTANCE_ANNOUNCEMENT](state, data) {
    state.remittanceAnnouncement = data
  },
  [SET_REMITTANCE_HISTORY_ITEMS](state, data) {
    state.remittanceHistoryItems = data
  },
  [SET_SELECTED_SUMMARY_ITEMS](state, data) {
    state.selectedSummaryItems = data
  },
  [SET_SELECTED_PENDING_ITEMS](state, data) {
    state.selectedPendingItems = data
  },
  [SET_SELECTED_REMITTANCE_KEY](state, data) {
    state.selectedRemittanceKey = data
  },
  [SET_STATE_LIST](state, data) {
    state.stateList = data
  },
  [SET_SUMMARY_DETAILS](state, data) {
    state.selectedSummaryDetails = data
  },
  [SET_SUMMARY_ITEMS](state, data) {
    state.summaryItemsFromEntry = data
  },
  [SET_SUMMARY_MULTICAMP_ITEMS](state, data) {
    state.summaryItemsMulticampFromEntry = data
  },
  [SET_SUMMARY_PRESENTATIONS](state, data) {
    state.summaryPresentationFromEntry = data
  },
  [SET_THE_REMITTANCE_KEY](state, data) {
    state.theRemittanceKey = data
  }
}

export const actions = {
  async deletePending({ dispatch, rootGetters }, parms) {
    try {
      const state_camp = rootGetters['campModule/stateOrCamp']
      const hst = await rootGetters['campModule/homeState']
      const cmp = rootGetters['campModule/homeCamp']
      if (!!parms.org_key) {
        const response = await apiCalls.deletePendingTransaction(parms.org_key, parms.rmt_key, parms.ind_key)
        if (response && response.status === 200) {
          if (response.data !== null) {
            dispatch('getPendingRemittances', {
              org_key: state_camp === 'c' ? cmp.key : hst.value,
              tdate: parms.tdt
            })
          }
          return true
        }
      }
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async deleteRemittance({ dispatch, rootGetters }, parms) {
    try {
      const state_camp = rootGetters['campModule/stateOrCamp']
      const hst = await rootGetters['campModule/homeState']
      const cmp = rootGetters['campModule/homeCamp']
      if (!!parms.rmt_key) {
        const response = await apiCalls.deleteRemittance(parms.rmt_key, parms.ind_key)
        if (response && response.status === 200) {
          if (response.data !== null) {
            await dispatch('getPendingRemittancesForOrg', state_camp === 'c' ? cmp.key : hst.value)
          }
          return true
        }
      }
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getAllStates({ commit }) {
    try {
      const response = await apiCalls.getStateData()
      if (response && response.status === 200) {
        if (response.data !== null) {
          await commit('SET_STATE_LIST', response.data)
        }
        return true
      }
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getSummaryDetails({ commit }, parms) {
    try {
      if (!!parms.rmt_key) {
        const response = await apiCalls.getDetailsByKey(parms.org_key, parms.rmt_date, parms.rmt_key, parms.multicamp, parms.status)
        if (response && response.status === 200) {
          if (response.data !== null) {
            await commit('SET_SUMMARY_DETAILS', response.data)
          }
          return true
        }
      }
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getPendingDetails({ commit }, rmt_key, multicamp) {
    try {
      if (!!rmt_key) {
        const response = await apiCalls.getPendingDetailsByKey(rmt_key, multicamp)
        if (response && response.status === 200) {
          if (response.data !== null) {
            await commit('SET_PENDING_DETAILS', response.data)
          }
          return true
        }
      }
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getPendingDetailsByOrgAndKey({ commit, dispatch }, parms) {
    try {
      if (!!parms.org_key) {
        const response = await apiCalls.getPendingDetailsByOrgAndKey(parms.org_key, parms.rmt_key, parms.multicamp)
        if (response && response.status === 200) {
          if (response.data.length > 0) {
            if (response.data[0].rmt_key !== null) {
              await commit('SET_THE_REMITTANCE_KEY', response.data[0].rmt_key)
            }
          }
          //  get collection of rmd_cmp_org_key to retrieve camp name and number
          let rmd_cmp_org_key_Collection = []
          response.data.map(rs => rs.remittanceDetail.map(rd =>
            rmd_cmp_org_key_Collection.push(rd.rmd_cmp_org_key)))
          await commit('SET_RAW_PENDING_ITEMS', response.data)
          await dispatch('amendRawPendingItems', rmd_cmp_org_key_Collection)
          if (parms.multicamp) {
            await dispatch('setPendingMulticampItemsFromRaw')
          } else {
            await dispatch('setPendingItemsFromRaw')
          }
          return true

        }
      }
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getPendingRemittances({ commit, dispatch }, parms) {
    try {
      if (!!parms.org_key) {
        const response = await apiCalls.getPendingByDate(parms.org_key, parms.tdate, parms.multicamp)
        if (response && response.status === 200) {
          if (response && response.status === 200) {
            if (response.data.length > 0) {
              if (response.data[0].rmt_key !== null) {
                await commit('SET_THE_REMITTANCE_KEY', response.data[0].rmt_key)
              }
            }

            //  get collection of rmd_cmp_org_key to retrieve camp name and number
            let rmd_cmp_org_key_Collection = response.data.map(rc => rc.remittanceDetail[0].rmd_cmp_org_key)
            await commit('SET_RAW_PENDING_ITEMS', response.data)
            if (response.data.length > 0) {
              await dispatch('amendRawPendingItems', rmd_cmp_org_key_Collection)

              if (parms.multicamp) {
                await dispatch('setPendingMulticampItemsFromRaw')
              } else {
                await dispatch('setPendingItemsFromRaw')
              }
            } else {
              await dispatch('resetTheRemittanceKey')
              await commit('SET_PENDING_REMITTANCES', [])
            }
            return true
          }
        }
      }
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async amendRawPendingItems({ commit }, org_key_list) {
    try {
      if (org_key_list.length > 0) {
        const response = await apiCalls.getCampStateData(org_key_list)
        if (response && response.status === 200) {
          if (response.data.length > 0) {
            await commit('AMEND_RAW_PENDING_ITEMS', response.data)
          }
        }
        return true
      }
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getPendingRemittancesForOrg({ commit, dispatch }, org_key) {
    try {
      if (!!org_key) {
        const response = await apiCalls.getPendingRemittancesForOrg(org_key)
        if (response && response.status === 200) {
          if (response.data.length > 0 && response.data[0].Key !== null) {
            await commit('SET_PENDING_REMITTANCES', response.data)
          } else {
            await dispatch('resetTheRemittanceKey')
            await commit('SET_PENDING_REMITTANCES', [])
          }
          return true
        }
      }
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getPendingSummary({ commit }, parms) {

    try {
      if (!!parms.org_key) {
        const response = await apiCalls.getPendingSummaryByOrg(parms.org_key, parms.multicamp)
        if (response && response.status === 200) {
          if (response.data && response.data.length > 0 && response.data[0].rmt_key !== null) {
            const ndt = new Date()
            const mm = ((ndt.getMonth() + 1) < 10 ? '0' : '') + ndt.getMonth() + 1
            const dd = (ndt.getDate() < 10 ? '0' : '') + ndt.getDate()
            const yyyy = ndt.getFullYear()
            await commit(
              'SET_PENDING_SUMMARY_FOR_ORG',
              response.data.filter(dt => {
                return dt.rmt_type === 'Pending' && dt.rmt_date !== mm + '/' + dd + '/' + yyyy
              })
            )
            await commit(
              'SET_COMPLETED_SUMMARY_FOR_ORG',
              response.data.filter(dt => {
                return dt.rmt_type === 'Completed'
              })
            )
          }
          return true
        }
      }
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getRemittanceAnnouncement({ commit }) {

    try {
      const response = await apiCalls.getRemittanceAnnouncement()
      if (response && response.status === 200) {
        commit('SET_REMITTANCE_ANNOUNCEMENT', response.data)
        return true
      } else {
        throw 'Bad response during remittance announcement retrieval.'
      }
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getRemittanceHistoryByDate({ commit }, parms) {

    try {
      if (!!parms.org_key) {
        const response = await apiCalls.getRemittanceHistoryByDate(parms.org_key, parms.start_date, parms.end_date)
        if (response && response.status === 200) {
          commit('SET_REMITTANCE_HISTORY_ITEMS', response.data)
          return true
        } else {
          throw 'Bad response during remittance history request.'
        }
      }
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getRemittanceHistoryTop10({ commit }, org_key) {

    try {
      if (!!org_key) {
        const response = await apiCalls.getRemittanceHistoryTop10(org_key)
        if (response && response.status === 200) {
          commit('SET_REMITTANCE_HISTORY_ITEMS', response.data)
          return true
        } else {
          throw 'Bad response during remittance history request.'
        }
      }
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getSummaryByOrg({ commit }, parms) {

    try {
      if (!!parms.org_key) {
        const response = await apiCalls.getSummaryByOrg(parms.org_key, parms.status, parms.rmt_key)
        if (response && response.status === 200) {
          commit('SET_SELECTED_SUMMARY_ITEMS', response.data)
          return true
        } else {
          throw 'Bad response during remittance request.'
        }
      }
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async processPreauth({ commit, state }) {
    try {
      if (state.preauthed === false) {
        const response = await apiCalls.processPreauth()
        if (response && response.status === 200) {
          commit('SET_PREAUTHORIZED_FLAG', response.data === 'good')
        }
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },

  async resubmitRemittance({ dispatch, rootGetters }, rmt_key) {
    try {
      if (!!rmt_key && rmt_key.length === 36) {
        const userKey = await rootGetters['user/userId']
        const response = await apiCalls.resubmitRemittance(rmt_key, userKey)
        if (response && response.status === 200) {
          //  netForum batch GUID is returned
          if (response.data.length === 36 && response.data.split('-').length === 5) {
            await dispatch('resetTheRemittanceKey')
            return true
          } else {
            throw `The following message was returned from the process request. ==> ${rmt_key}`
          }
        }
      } else {
        throw `An invalid remittance key was used in a process request. ==> ${rmt_key}`
      }
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async processRemittance({ dispatch, rootGetters }, rmt_key) {
    try {
      if (!!rmt_key && rmt_key.length === 36) {
        const userKey = await rootGetters['user/userId']
        const response = await apiCalls.processRemittance(rmt_key, userKey)
        if (response && response.status === 200) {
          //  netForum batch GUID is returned
          if (response.data.length === 36 && response.data.split('-').length === 5) {
            await dispatch('resetTheRemittanceKey')
            return true
          } else {
            throw `The following message was returned from the process request. ==> ${rmt_key}`
          }
        }
      } else {
        throw `An invalid remittance key was used in a process request. ==> ${rmt_key}`
      }
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async resetTheRemittanceKey({ commit }) {
    commit('SET_THE_REMITTANCE_KEY', '')
    commit('SET_PENDING_ITEMS', [])
    commit('SET_MULTICAMP_PENDING_ITEMS', [])
    return true
  },
  async savePendingTransaction({ state, rootGetters, commit }, rData) {

    try {
      const state_camp = rootGetters['campModule/stateOrCamp']
      const ccmp = await rootGetters['campModule/currentCamp']
      const hcmp = await rootGetters['campModule/homeCamp']
      const hst = await rootGetters['campModule/stateUndist']
      const userKey = await rootGetters['user/userId']
      let stringObj = `"rmt_key": "${rData.key}", "multicamp":"${rData.multicamp}", "rmt_submitted_by_ind_key": "${userKey}", "rmt_org_key": "${state_camp === 'c' ? hcmp.key : hst.cmp_org_key
        }",  `
      let remittanceDetailString = ""
      let remittanceDetailArray = []
      let remittanceFundString = ""
      let remittanceFundObj = ""
      if (rData.multicamp) {
        state.summaryItemsMulticampFromEntry.map(item => {
          remittanceDetailString = `"rmd_key": "${item.rmdKey == undefined ? '' : item.rmdKey}",  "rmd_cmp_org_key": "${item.camp_key}","rmd_date": "${rData.date}", `
          remittanceFundString = ""
          state.pendingMultiCampModelNames.map(pmn => {
            const si = [...state.summaryItemsMulticampFromEntry].find(sf => {
              return sf.field === pmn
            })
            if (!!si) {
              remittanceFundString += ` "${si.field}": "${item.amount}", `
              if (si.countField) {
                remittanceFundString += ` "${si.countField}": "${item.count}", `
              }
            } else {
              remittanceFundString += ` "${pmn}": "0", `
            }
          })
          remittanceFundObj = ' { ' + remittanceFundString.substring(0, remittanceFundString.length - 2) + ' }'
          remittanceDetailString += `"remittanceFunds": ${remittanceFundObj}`
          remittanceDetailArray.push(`{${remittanceDetailString}}`)
        })

      } else {
        remittanceDetailString = `"rmd_key": "${rData.rmd}", "rmd_cmp_org_key": "${ccmp.key}","rmd_date": "${rData.date}", `
        state.pendingModelNames.map(pmn => {
          const si = [...state.summaryItemsFromEntry, ...state.summaryPresentationFromEntry].find(sf => {
            return sf.field === pmn
          })
          if (!!si) {
            remittanceFundString += ` "${si.field}": "${si.amount}", `
          } else {
            remittanceFundString += ` "${pmn}": "0", `
          }
        })
        remittanceFundObj = ' { ' + remittanceFundString.substring(0, remittanceFundString.length - 2) + ' }'
        remittanceDetailString += `"remittanceFunds": ${remittanceFundObj}`
        remittanceDetailArray.push(`{${remittanceDetailString}}`)
      }
      let remittanceDetail = `[${remittanceDetailArray}]`
      stringObj += `"remittanceDetail": ${remittanceDetail}`
      let pendingItem = JSON.parse(`{${stringObj}}`)
      const response = await apiCalls.postPending(pendingItem)
      if (response && response.status === 200) {
        if (response.data.length === 36 && response.data.split('-').length === 5) {
          await commit('SET_SELECTED_REMITTANCE_KEY', response.data)
          return response.data
        }
        return reponse.data
      }
    } catch (error) {
      console.error(error)
      return 0
    }
  },
  async setPendingMulticampItemsFromRaw({ commit, state }) {
    const dt = new Date()
    let mrModel = JSON.parse(JSON.stringify(state.multiRemittance))
    let total = 0
    let field = {}
    let rmtKey = ''
    let rmtDate = null
    let rmtCamps = []
    const pendingItems = await state.rawPendingItems.map(rp => {
      const remitDetail = rp.remittanceDetail[0]
      rmtDate =
        remitDetail.rmd_date !== ''
          ? remitDetail.rmd_date
          : ((dt.getMonth() + 1) < 10 ? '0' : '') + (dt.getMonth() + 1) +
          '/' +
          (dt.getDate() < 10 ? '0' : '') + dt.getDate() +
          '/' +
          dt.getFullYear()
      rmtKey = rp.rmt_key.toLowerCase()
      let fundObj = Object.keys(rp.remittanceDetail[0].remittanceFunds)
        .filter(key => rp.remittanceDetail[0].remittanceFunds[key] > 0)
        .reduce((obj, key) => {
          obj[key] = rp.remittanceDetail[0].remittanceFunds[key];
          return obj;
        }, {});

      let countObj = Object.keys(fundObj).reduce((result, k) => {
        if (k.includes("num")) {
          result[k] = fundObj[k];
          delete fundObj[k];
        }
        return result;
      }, {});
      var camp = {
        key: remitDetail.rmd_cmp_org_key,
        camp_name: rp.camp_name,
        camp_number: rp.camp_number,
        camp_rmd_key: !!remitDetail.rmd_key ? remitDetail.rmd_key.toLowerCase() : '',
        amount: parseFloat(Object.values(fundObj)).toFixed(2),
        count: parseInt(Object.values(countObj))
      }
      let remittanceFundObj = state.multicampRemittanceItems.filter(mri => {
        return mri.value == Object.keys(fundObj)[0]
      })
      total += parseFloat(Object.values(fundObj))
      rmtCamps.push(camp)
      field = { value: remittanceFundObj[0].value, text: remittanceFundObj[0].text, countField: remittanceFundObj[0].countField, countText: remittanceFundObj[0].countText }
    })
    mrModel.field = field
    mrModel.total = total.toFixed(2)
    mrModel.date = rmtDate
    mrModel.rmt_key = rmtKey
    mrModel.camp = rmtCamps
    let multicampPendingItems = []
    multicampPendingItems.push(mrModel)
    await commit('SET_MULTICAMP_PENDING_ITEMS', multicampPendingItems)

  },
  async setPendingItemsFromRaw({ commit, state }) {
    const dt = new Date()
    const pendingItems = await state.rawPendingItems.map(rp => {
      let rModel = JSON.parse(JSON.stringify(state.remittanceModel))
      const remitDetail = rp.remittanceDetail[0]
      rModel.date =
        remitDetail.rmd_date !== ''
          ? remitDetail.rmd_date
          : ((dt.getMonth() + 1) < 10 ? '0' : '') + (dt.getMonth() + 1) +
          '/' +
          (dt.getDate() < 10 ? '0' : '') + dt.getDate() +
          '/' +
          dt.getFullYear()
      rModel.rmt_key = rp.rmt_key.toLowerCase()
      rModel.camp.key = remitDetail.rmd_cmp_org_key.toLowerCase()
      rModel.camp.number = rp.camp_number
      rModel.camp.name = rp.camp_name
      rModel.camp.rmd_key = !!remitDetail.rmd_key ? remitDetail.rmd_key.toLowerCase() : ''
      let remitFunds = remitDetail.remittanceFunds
      rModel.transactions.map((trx, idx) => {
        switch (idx) {
          case 0:
            trx.accounts[0].amount = remitFunds.ch_receipt.toFixed(2)
            trx.accounts[1].amount = remitFunds.ch_presentation_num
            break
          case 1:
            trx.accounts[0].amount = remitFunds.gc_receipt.toFixed(2)
            trx.accounts[1].amount = remitFunds.gc_donor_num
            //dummy position
            trx.accounts[3].amount = remitFunds.gc_presentation_gid_num
            //dummy position
            trx.accounts[5].amount = remitFunds.gc_presentation_aux_num
            trx.accounts[6].amount = remitFunds.bj_receipt.toFixed(2)
            trx.accounts[7].amount = remitFunds.bj_church_num
            break
          case 2:
            trx.accounts[0].amount = remitFunds.aux_script_fund.toFixed(2)
            break
          case 3:
            trx.accounts[0].amount = remitFunds.ff_annual_pastor_event.toFixed(2)
            trx.accounts[1].amount = remitFunds.ff_int_conv_gid.toFixed(2)
            trx.accounts[2].amount = remitFunds.ff_area_mtg.toFixed(2)
            trx.accounts[3].amount = remitFunds.ff_int_conv_aux.toFixed(2)
            trx.accounts[4].amount = remitFunds.ff_sta_conv_cdn.toFixed(2)
            trx.accounts[5].amount = remitFunds.ff_aux_evt.toFixed(2)
            trx.accounts[6].amount = remitFunds.ff_sta_conv_aux.toFixed(2)
            trx.accounts[7].amount = remitFunds.ff_cmp_ff.toFixed(2)
            break
          case 4:
            trx.accounts[0].amount = remitFunds.oth_cmp_scr_fund.toFixed(2)
            trx.accounts[1].amount = remitFunds.oth_usa_out_fund.toFixed(2)
            break
          case 5:
            trx.accounts[0].amount = remitFunds.bf_cmp_fund.toFixed(2)
            trx.accounts[1].amount = remitFunds.bf_sta_fund.toFixed(2)
            break
        }
      })
      rModel.total = (
        parseFloat(remitFunds.ch_receipt) +
        parseFloat(remitFunds.ff_annual_pastor_event) +
        parseFloat(remitFunds.ff_area_mtg) +
        parseFloat(remitFunds.ff_int_conv_gid) +
        parseFloat(remitFunds.ff_int_conv_aux) +
        parseFloat(remitFunds.ff_sta_conv_cdn) +
        parseFloat(remitFunds.ff_sta_conv_aux) +
        parseFloat(remitFunds.ff_cmp_ff) +
        parseFloat(remitFunds.ff_aux_evt) +
        parseFloat(remitFunds.gc_receipt) +
        parseFloat(remitFunds.bj_receipt) +
        parseFloat(remitFunds.aux_script_fund) +
        parseFloat(remitFunds.oth_cmp_scr_fund) +
        parseFloat(remitFunds.oth_usa_out_fund) +
        parseFloat(remitFunds.bf_cmp_fund) +
        parseFloat(remitFunds.bf_sta_fund)
      ).toFixed(2)

      return rModel
    })
    await commit('SET_PENDING_ITEMS', pendingItems)
    return true
  },
  setPendingRemittanceTime({ commit }) {
    commit('SET_PENDING_REMITTANCE_TIME')
  },
  async setPresentationSummaryItems({ commit }, items) {
    commit('SET_SUMMARY_PRESENTATIONS', items)
    return true
  },
  async setSelectedRemittanceKey({ commit }, rkey) {
    commit('SET_SELECTED_REMITTANCE_KEY', rkey.toLowerCase())
    return true
  },
  async setShowTable({ commit }, data) {

    commit('SET_SHOW_TABLE', data)
  },
  async setSummaryItems({ commit }, items) {
    commit('SET_SUMMARY_ITEMS', items.sort((a, b) => a.order - b.order))
    return true
  },
  async setSummaryForMulticampItems({ commit }, items) {
    commit('SET_SUMMARY_MULTICAMP_ITEMS', items.sort((a, b) => a.order.localeCompare(b.order)))
    return true
  }
}

const apiCalls = {
  // eslint-disable-next-line camelcase
  deletePendingTransaction(org_key, rmt_key, ind_key) {
    const route = API_CONFIG.deletePendingTransaction(org_key, rmt_key, ind_key)
    return axios.delete(route)
  },
  deleteRemittance(rmt_key, ind_key) {
    const route = API_CONFIG.deleteRemittance(rmt_key, ind_key)
    return axios.delete(route)
  },
  getDetailsByKey(org_key, rmt_date, rmt_key, multicamp, status) {
    const route = API_CONFIG.getDetailsByKey(org_key, rmt_date, rmt_key, multicamp, status)
    return axios.get(route)
  },
  getPendingByDate(org_key, tdate, multicamp) {
    const route = API_CONFIG.getPendingRemittancesByDate(org_key, tdate, multicamp)
    return axios.get(route)
  },
  getPendingDetailsByKey(rmt_key, multicamp) {
    const route = API_CONFIG.getPendingDetailsByKey('a', '0', multicamp, rmt_key)
    return axios.get(route)
  },
  getPendingDetailsByOrgAndKey(org_key, rmt_key, multicamp) {
    const route = API_CONFIG.getPendingDetailsByKey(org_key, '0', multicamp, rmt_key)
    return axios.get(route)
  },
  getPendingRemittancesForOrg(org_key) {
    const route = API_CONFIG.getPendingRemittances(org_key)
    return axios.get(route)
  },
  getPendingSummaryByOrg(org_key, multicamp) {
    const route = API_CONFIG.getPendingSummaryByOrg(org_key, multicamp)
    return axios.get(route)
  },
  getRemittanceAnnouncement() {
    const route = API_CONFIG.getRemittanceAnnouncement()
    return axios.get(route)
  },
  getRemittanceHistoryByDate(org_key, start_date, end_date) {
    const route = API_CONFIG.getRemittanceHistoryByDate(org_key, start_date, end_date)
    return axios.get(route)
  },
  getRemittanceHistoryTop10(org_key) {
    const route = API_CONFIG.getRemittanceHistoryTop10(org_key)
    return axios.get(route)
  },
  getCampStateData(payload) {
    const route = API_CONFIG.getCampStateData()
    return axios.post(route, payload)
  },
  getStateData() {
    const route = API_CONFIG.getStateData([EMPTY_GUID])
    return axios.get(route)
  },
  getSummaryByOrg(org_key, status, rmt_key) {
    const route = API_CONFIG.getSummaryByOrg(org_key, status, rmt_key)
    return axios.get(route)
  },
  processPreauth() {
    const route = API_CONFIG.processPreauth()
    return axios.get(route)
  },
  processRemittance(rmt_key, ind_key) {
    const route = API_CONFIG.processRemittance(rmt_key, ind_key)
    return axios.post(route)
  },
  postPending(data) {
    const route = API_CONFIG.savePendingTransaction()
    return axios.post(route, data)
  },
  resubmitRemittance(rmt_key, ind_key) {
    const route = API_CONFIG.resubmitRemittance(rmt_key, ind_key)
    return axios.post(route)
  }
}

export const remittanceModule = {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}
