/* eslint-disable */
export const GIDEON_STORE_API_ACTIVE_ROUTE = `${process.env.VUE_APP_API_ROUTE_ADDRESS}/store`

export const GIDEON_STORE_API_CONFIG = {
  getStoreLandingPage: (lang, cmp_key, landingpage) =>
    `${GIDEON_STORE_API_ACTIVE_ROUTE}/gettc3storelandingpage/${lang}/${cmp_key}/${landingpage}`,
  getStoreNavigationPage: (lang, ind_key, landingpage) =>
    `${GIDEON_STORE_API_ACTIVE_ROUTE}/gettc3storenavigation/${lang}/${ind_key}/${landingpage}`,
  getStoreSubcategoryPage: (lang, subcatid) =>
    `${GIDEON_STORE_API_ACTIVE_ROUTE}/gettc3storesubcategoryproducts/${lang}/${subcatid}`,
  getStoreProductDetailPage: (lang, prd_key, ind_key, landingpage) =>
    `${GIDEON_STORE_API_ACTIVE_ROUTE}/gettc3storeproductdetails/${lang}/${prd_key}/${ind_key}/${landingpage}`,
  getStoreFavorites: (lang, ind_key, landingpage) =>
    `${GIDEON_STORE_API_ACTIVE_ROUTE}/gettc3storefavorites/${lang}/${ind_key}/${landingpage}`,
  getStoreCart: (lang, ind_key, cmp_key, landingpage) =>
    `${GIDEON_STORE_API_ACTIVE_ROUTE}/gettc3storecart/${lang}/${ind_key}/${cmp_key}/${landingpage}`,
  getStoreCheckout: (lang, ind_key, org_key, landingpage, product_type) =>
    `${GIDEON_STORE_API_ACTIVE_ROUTE}/gettc3storecheckout/${lang}/${ind_key}/${org_key}/${landingpage}/${product_type}`,
  getStoreConfirmation: (lang, ind_key, landingpage, product_type, orderid) =>
    `${GIDEON_STORE_API_ACTIVE_ROUTE}/gettc3storepurchaseconfirmation/${lang}/${ind_key}/${landingpage}/${product_type}/${orderid}`,
  getStoreOrderHistory: (lang, ind_key, landingpage, org_key) =>
    `${GIDEON_STORE_API_ACTIVE_ROUTE}/gettc3storeorderhistory/${lang}/${ind_key}/${landingpage}/${org_key}`,
  getStoreSearch: (lang, landingpage) => `${GIDEON_STORE_API_ACTIVE_ROUTE}/gettc3storesearch/${lang}/${landingpage}`,
  getStoreProductSearch: (lang, criteria, product_type) =>
    `${GIDEON_STORE_API_ACTIVE_ROUTE}/gettc3storeproductsearch/${lang}/${criteria}/${product_type}`,
  getStoreCheckoutShippingAddress: (ind_key, current_ind_key) =>
    `${GIDEON_STORE_API_ACTIVE_ROUTE}/gettc3storecheckoutshippingaddresses/${ind_key}/${current_ind_key}`,
  updateStoreCart: (ind_key, prd_key, quantity) =>
    `${GIDEON_STORE_API_ACTIVE_ROUTE}/updatetc3storecart/${ind_key}/${prd_key}/${quantity}`,
  clearStoreCart: (ind_key, product_type) =>
    `${GIDEON_STORE_API_ACTIVE_ROUTE}/cleartc3storecart/${product_type}/${ind_key}`,
  updateStoreFavorites: (ind_key, prd_key, isfavorite) =>
    `${GIDEON_STORE_API_ACTIVE_ROUTE}/updatetc3storefavorites/${ind_key}/${prd_key}/${isfavorite}`,
  addStorePurchase: () => `${GIDEON_STORE_API_ACTIVE_ROUTE}/posttc3storepurchase/`,
  processPreauthNF: () => `${GIDEON_STORE_API_ACTIVE_ROUTE}/processpreauthnf/`,
}
