/* eslint-disable */
import { API_CONFIG } from '@/config/api.config'
import axios from 'axios'

export const state = () => ({
  donationModel: {
    transactions: [
      // {
      //   amount: 0,
      //   category: 'tcEventMeal', //hide for future version
      //   text: '',
      //   field: 'don_event_meal',
      //   campaignCode: '',
      //   fundCode: '',
      //   sourceCode: 'CONGive',
      //   childItem: 0,
      //   displayOnly: 0
      // },
      // {
      //   amount: 0,
      //   category: 'tcCampGeneralFund', //hide for future version
      //   text: '',
      //   field: 'don_camp_general',
      //   campaignCode: 'WEB',
      //   fundCode: 'DONGEN',
      //   sourceCode: 'CONGive',
      //   childItem: 0,
      //   displayOnly: 0
      // },
      {
        amount: 0,
        category: 'tcGideonCard',
        text: '',
        field: 'don_gideoncard',
        campaignCode: 'WEB',
        fundCode: 'DONGC',
        sourceCode: 'CONGive-GC',
        childItem: 0,
        displayOnly: 0
      },
      {
        amount: 0,
        category: 'tcAuxScriptureFund',
        text: '',
        field: 'don_aux_scripture',
        campaignCode: 'WEB',
        fundCode: 'DONAUXS',
        sourceCode: 'CONGive-AUXS',
        childItem: 0,
        displayOnly: 0
      },
      {
        amount: 0,
        category: 'tcFaithFund',
        text: '',
        field: 'don_faith_fund',
        campaignCode: 'WEB',
        fundCode: 'DONFF',
        sourceCode: 'CONGive',
        childItem: 0,
        displayOnly: 1
      },
      {
        amount: 0,
        category: 'tcAnnualPastorsEvent',
        text: '',
        field: 'don_annual_pastors_event',
        campaignCode: 'WEB',
        fundCode: 'DONFF',
        sourceCode: 'CONGive-PAB',
        childItem: 1,
        displayOnly: 0
      },
      {
        amount: 0,
        category: 'tcAreaFaithFundMeeting',
        text: '',
        field: 'don_area_faith_fund_rally',
        campaignCode: 'WEB',
        fundCode: 'DONFF',
        sourceCode: 'CONGive-FFRally',
        childItem: 1,
        displayOnly: 0
      },
      {
        amount: 0,
        category: 'tcStateConventionCountdown',
        text: 'tcCountdown100',
        field: 'don_countdown_100',
        campaignCode: 'STCV',
        fundCode: 'DONFF',
        sourceCode: 'CONGive-CD100',
        childItem: 1,
        displayOnly: 0
      },
      {
        amount: 0,
        category: 'tcStateConventionAux',
        text: 'tcAuxHeartProgram',
        field: 'don_aux_heart_program',
        campaignCode: 'STCVA',
        fundCode: 'DONFF',
        sourceCode: 'CONGive-AUXHP',
        childItem: 1,
        displayOnly: 0
      },
      {
        amount: 0,
        category: 'tcIntlConventionFF',
        text: 'tcSummerFaithFundRally',
        field: 'don_intl_convention_ff',
        campaignCode: 'CNV',
        fundCode: 'DONFF',
        sourceCode: 'CONGive-FFRally',
        childItem: 1,
        displayOnly: 0
      },
      {
        amount: 0,
        category: 'tcIntlConventionAux',
        text: 'tcSupportingAuxLuncheon',
        field: 'don_intl_convention_aux',
        campaignCode: 'CNVA',
        fundCode: 'DONFF',
        sourceCode: 'CONGive',
        childItem: 1,
        displayOnly: 0
      },
      {
        amount: 0,
        category: 'tcAuxEvent',
        text: '',
        field: 'don_aux_event',
        campaignCode: 'WEB',
        fundCode: 'DONFF',
        sourceCode: 'CONGive-AUXE',
        childItem: 1,
        displayOnly: 0
      },
      {
        amount: 0,
        category: 'tcOtherCampFaithFundGift',
        text: '',
        field: 'don_other_ff_gift',
        campaignCode: 'WEB',
        fundCode: 'DONFF',
        sourceCode: 'CONGive',
        childItem: 1,
        displayOnly: 0
      },
      {
        amount: 0,
        category: 'tcCampScriptureFund',
        text: '',
        field: 'don_camp_scripture',
        campaignCode: 'WEB',
        fundCode: 'DONCSF',
        sourceCode: 'CONGive-CSF',
        childItem: 0,
        displayOnly: 0
      },
      {
        amount: 0,
        category: 'tcBarnabasFund',
        text: '',
        field: 'don_barnabas',
        campaignCode: 'WEB',
        fundCode: 'DONBF',
        sourceCode: 'CONGive-BF',
        childItem: 0,
        displayOnly: 0
      }
    ]
  },
  donationItemsFromEntry: []
})
export const getters = {
  donationModel: state => {
    return state.donationModel
  },
  donationsSummary: state => {
    return state.donationItemsFromEntry || []
  }
}
export const actions = {
  async setDonationItems({ commit }, items) {
    await commit('SET_DONATION_ITEMS', items)
    return true
  },
  async processDonationPayment({ dispatch }, payload){
    try {
      const response = await apiCalls.processDonation(payload)
      if (response && response.status === 200) {
        //  netForum batch GUID is returned
        if (response.data.length === 36 && response.data.split('-').length === 5) {
          return true
        } else {
          return response.data
        }
      }

    } catch (error) {
      console.error(error)
      return error.data
    }
  }
}
export const SET_DONATION_ITEMS = 'SET_DONATION_ITEMS'
export const mutations = {
  [SET_DONATION_ITEMS](state, data) {
    state.donationItemsFromEntry = data
  },
}
const apiCalls = {
  processDonation(payload){
    const route = API_CONFIG.processDonationPayment()
    return axios.post(route, payload)
  }
}
export const donationModule = {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}